import React, { useEffect, useState } from "react";
import "../../assets/css/enterprise.css";
import "../../assets/css/services.css";
import { Fade } from "react-reveal";
import Footer from "../../wrappers/Footer";
import Header from "../../wrappers/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import gesDenetimleriData from "../../data/ges_denetimleri.json";
import {Helmet} from "react-helmet";

const Denetim = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  return (
    <>
      <Helmet>
        <title>Denetim ve Sertifikasyon | Deka Solar
</title>
        <meta name="description" content="Unleashing a power-packed digital experience."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/hizmetlerimiz/denetim-ve-sertifikasyon"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container justify-content-center align-items-center services-main services-inner">
            <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h_160 mt-5">
                <div className="col-lg-9 col-12 text-white d-flex flex-column justify-content-end p-3 mt-5 h_38">
                  <h1>
                    <b>Hizmetlerimiz</b>
                  </h1>
                  <h5>
                    <b>deka enerji olarak,</b>
                  </h5>
                  <p>
                    Enerji sektöründeki müşterilerimize kapsamlı hizmetler
                    sunarak projelerinin her aşamasında güvenilir bir ortak
                    oluyoruz.
                  </p>
                </div>
                <div className="col-12 d-flex align-items-end flex-wrap p-3">
                  <div className="service-cards d-flex flex-wrap  gap-2 w-100 justify-content-between">
                    <div className="services_each_card col-lg-2 col-5 p-3">
                      <h5>İşveren Mühendisliği</h5>

                      <a href="/hizmetlerimiz/isveren-muhendisligi">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Fizibilite Raporu Hazılanması</h5>

                      <a href="/hizmetlerimiz/fizibilite-raporu-hazilanmasi">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Denetim ve Sertifikasyon</h5>

                      <a href="/hizmetlerimiz/denetim-ve-sertifikasyon">
                        <button className="services-inner-button-type-four">
                          daha fazla
                        </button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>İşletme & Bakım</h5>
                      <a href="/hizmetlerimiz/isletme-ve-bakim">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Santral Kıymetlendirme</h5>
                      <a href="/hizmetlerimiz/santral-kiymetlendirme">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Araç Şarj İstasyonları</h5>
                      <a href="/hizmetlerimiz/arac-sarj-istasyonlari">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12">
            <h3 className="d-flex justify-content-start mt-4 mb-4">
             <b>Denetim ve Sertifikasyon
              </b> 
            </h3>
            <div className="d-lg-flex mt-3 mb-5 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="p-2 ">
                    <p>
                      DEKA Enerji Güneş Enerjisi Santrallerinde (GES) TS-EN
                      (IEC) 62446/61215/61646/60891 standartları kapsamında
                      sahada test ve denetim hizmetleri vermektedir.
                      Gerçekleştirilecek test ve denetimler aşağıdaki işlemleri
                      içermektedir:
                    </p>
                    <p className="m-0 p-0">
                      ・Saha ziyareti ile mekanik ve elektriksel denetimlerin
                      ger&ccedil;ekleştirilmesi,
                    </p>
                    <p className="m-0 p-0">
                      ・Topraklama ve S&uuml;reklilik testleri,
                    </p>
                    <p className="m-0 p-0">・Polarite Testleri,</p>
                    <p className="m-0 p-0">
                      ・Voc ve Isc &ouml;l&ccedil;&uuml;mleri,
                    </p>
                    <p className="m-0 p-0">・Fonksiyon Testleri,</p>
                    <p className="m-0 p-0">・İzolasyon direnci testleri,</p>
                    <p className="m-0 p-0">
                      ・PV mod&uuml;llerin y&uuml;ksek
                      &ccedil;&ouml;z&uuml;n&uuml;rl&uuml;kl&uuml; kameralara
                      sahip dronlarla termal denetimleri
                      ger&ccedil;ekleştirilerek hatalı olduğu tespit edilen
                      panellerin (hotspot, bağlantı hatası, dizi hataları ve
                      diğerleri) raporlar halinde işverene sunulması,
                    </p>
                    <p className="m-0 p-0">
                      ・El tipi termal kamera ile GES panosu, inverter ve
                      t&uuml;m bağlantı noktalarının incelenip aşırı ısınma olan
                      yerlerin raporlaması,
                    </p>
                    <p className="m-0 p-0">
                      ・Elektrol&uuml;minans (EL) &ouml;l&ccedil;&uuml;mleri ile
                      panellerdeki kırık, &ccedil;atlak, fabrika hatalarının
                      tespit edilmesi,
                    </p>
                    <p className="m-0 p-0">
                      ・Tesiste her bir string &ouml;zelinde IV-Curve
                      &ouml;l&ccedil;&uuml;mlerinin yapılarak LID, PID, Mikro
                      &ccedil;atlak ve panellerdeki degradasyonun işverene
                      raporlanması,
                    </p>
                    <p className="m-0 p-0">
                      ・Ger&ccedil;ek sıcaklık ve ışıma değerleri kullanılarak
                      PVsyst &uuml;zerinde ger&ccedil;ekleştirilecek
                      sim&uuml;lasyon ile santralin PR (Performans Oranı)
                      değerinin &ccedil;ıkarılması,
                    </p>
                    <p className="m-0 p-0">
                      ・Sistemde muhtemel hataların tespit edilerek
                      &ccedil;&ouml;z&uuml;m &ouml;nerilerinin sunulması.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="text-start mb-3">
              <b>
                ges denetimleri <br />
                neden önemli?
              </b>
            </h5>
            <div className="mb-5 service-slider">
              <Swiper
                spaceBetween={30}
                grabCursor={true}
                pagination={true}
                className="mySwiper"
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                modules={[Autoplay, Pagination]}
                breakpoints={{
                  1200: {
                    slidesPerView: 4,
                  },
                  900: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  380: {
                    slidesPerView: 1,
                  },
                }}
              >
                {gesDenetimleriData.map((ges, index) => (
                  <SwiperSlide key={ges.ges_no}>
                    <div className="d-lg-flex gap-2 justify-content-center">
                      <div className="col-lg-12 col-12 p-lg-0 p-3 h-100">
                        <div className="card-type-two text-start d-flex flex-column">
                          <img src={ges.img} alt={`img-${index}`} />
                          <div className="p-2">
                            <h5>
                              <b>{ges.title}</b>
                            </h5>
                            <p>{ges.desc}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <p className="p-0 mb-5 text-start">
              Güneş enerji santralinde düzenli test ve denetim hizmetleri,
              işletme güvenliği, ekipman performansı ve enerji verimliliğini
              artırmak için önleyici bir yaklaşım sunar. Bu hizmetler sayesinde,
              yüksek tehlike içeren durumlar önceden tespit edilerek müdahale
              edilebilir ve ciddi olaylar önlenebilir.
            </p>
            <Footer className="mt-5" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Denetim;
