import React, { useState, useEffect, useRef } from "react";
import "../assets/css/counter.css";
import icon1 from "../assets/images/solar_icon.svg";
import icon2 from "../assets/images/location_icon.svg";
import icon3 from "../assets/images/charge_icon.svg";

const Counter = () => {
  const [solarPower, setSolarPower] = useState(0);
  const [provinceCount, setProvinceCount] = useState(0);
  const [chargingStations, setChargingStations] = useState(0);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startCounters();
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 } // Intersection observer threshold
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  const startCounters = () => {
    const solarInterval = setInterval(() => {
      if (solarPower >= 33) {
        clearInterval(solarInterval);
      } else {
        setSolarPower(prevSolarPower =>
          prevSolarPower >= 33 ? 33 : prevSolarPower + 1
        );
      }
    }, 150);
  
    const provinceInterval = setInterval(() => {
      if (provinceCount >= 81) {
        clearInterval(provinceInterval);
      } else {
        setProvinceCount(prevProvinceCount =>
          prevProvinceCount >= 81 ? 81 : prevProvinceCount + 3
        );
      }
    }, 150);
  
    const chargingInterval = setInterval(() => {
      if (chargingStations >= 32) {
        clearInterval(chargingInterval);
      } else {
        setChargingStations(prevChargingStations =>
          prevChargingStations >= 32 ? 32 : prevChargingStations + 1
        );
      }
    }, 150);
  
    return () => {
      clearInterval(solarInterval);
      clearInterval(provinceInterval);
      clearInterval(chargingInterval);
    };
  };

  return (
    <div
      className="container d-lg-flex d-md-flex d-grid justify-content-center gap-lg-5 gap-3 counter-page mt-lg-0 mt-5"
      ref={counterRef}
    >
      <div className="col-lg-2 col-md-3 col-10 counter-item m-4">
        <div className="img-radius-counter">
          <img src={icon1} className="counter-img" alt="solar-icon" />
        </div>
        <p className="mt-lg-4 mt-2">güneş santrali</p>
        <p className="counter-title">
          <b>{solarPower}.19</b>
        </p>
        <p>mw</p>
      </div>
      <div className="col-lg-2 col-md-3 col-10 counter-item m-4">
        <div className="img-radius-counter">
          <img src={icon2} className="counter-img" alt="location-icon" />
        </div>
        <p className="mt-lg-4 mt-2">türkiye'nin her ilinde</p>
        <p className="counter-title">
          <b>{provinceCount}</b>
        </p>
        <p>il</p>
      </div>
      <div className="col-lg-2 col-md-3 col-10 counter-item m-4">
        <div className="img-radius-counter">
          <img src={icon3} className="counter-img" alt="charge-icon" />
        </div>
        <p className="mt-lg-4 mt-2">sarj istasyon ağı</p>
        <p className="counter-title">
          <b>{chargingStations}</b>
        </p>
        <p>soket</p>
      </div>
    </div>
  );
};

export default Counter;
