import React, { useState, useEffect, useRef } from "react";
import { Fade } from "react-reveal";
import { IoChevronBackSharp } from "react-icons/io5";
import "../assets/css/services.css";
import { MdArrowForwardIos } from "react-icons/md";
import servicesData from "../data/services.json"; // JSON verilerini içe aktarın
import noImg from "../assets/images/kurumsal-hizmetler-deka.png";
import Header from "../wrappers/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import "../assets/css/services.css";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";

const Services = () => {
  const { services } = servicesData; // "services" özelliğini alın
  const [slideRight, setSlideRight] = useState(false);
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [movedToLeft, setMovedToLeft] = useState(false);
  SwiperCore.use([Pagination]);

  // Turkish characters removal function
  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
      "&": "ve",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ&]/g, (match) => turkishChars[match]);
  }

  // Swiper initialization function
  const handleSwiperInit = (swiper) => {
    setSwiperInstance(swiper);
  };

  // Back icon click handler
  const handleBackIconClick = () => {
    if (swiperInstance) {
      const transitionDuration = 1500;

      // Set transition duration for both movements
      swiperInstance.setTransition(transitionDuration);

      const containerWidth = swiperInstance.width; // Get width of swiper container
      const slideWidth = swiperInstance.slides[0].clientWidth; // Get width of each slide

      const slidesToShow = Math.floor(containerWidth / slideWidth);

      // Calculate the maximum translation value based on the number of slides to show
      const maxTranslate =
        -(swiperInstance.slides.length - slidesToShow) * slideWidth;

      if (!movedToLeft) {
        swiperInstance.setTranslate(maxTranslate, 0);
        setMovedToLeft(true);
        setSlideRight(true);
      } else {
        swiperInstance.slideTo(0, transitionDuration);
        setMovedToLeft(false);
        setSlideRight(false);
      }
    }
  };

  return (
    <>
      <div className="scroll-to-top">
        {" "}
        <ScrollToTop />
      </div>

      <div className="m-2">
        {" "}
        <div
          className={`container bg-white  d-flex justify-content-center align-items-center enterprise-main h-100`} /* ${
          enterprise-main  slideRight ? "slide-right" : ""
          } */
        >
          <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
            <div
              style={{ overflow: "hidden" }}
              className="col-12 text-start h-100 d-flex justify-content-end align-items-end"
            >
              <div className="col-lg-6 col-12 h-100 d-flex justify-content-between flex-column">
                <div className="col-lg-12 col-12 bg-white d-flex text-black align-items-center services-radius">
                  <div className="col-lg-4 col-md-4 p-lg-4 col-5 p-2">
                    {" "}
                    <img src={noImg} alt="deka solar enerji"></img>
                  </div>
                  <div className="col-lg-7 col-md-7 col-7 p-2">
                    <h5>
                      <b>Kurumsal Hizmetlerimiz</b>
                    </h5>
                    <p>
                      Projelerin her aşamasında enerji sektöründeki
                      müşterilerimize kapsamlı hizmetler sunarak güvenilir bir
                      iş ortağı oluyoruz.
                    </p>
                    <a aria-label="hizmetlerimiz-link-2" href="/hizmetlerimiz">
                      <button className="button-type">daha fazla</button>
                    </a>
                  </div>
                </div>
                <Swiper
                  ref={swiperRef}
                  onSwiper={handleSwiperInit}
                  slidesPerView={2}
                  className={`col-lg-12 col-12 text-white d-flex justify-content-start align-item-center p-1 mt-3 mb-3`} /*  ${
                    slideRight ? "slide-right" : ""
                  } */
                >
                  <div className="large-services service-cards d-flex">
                    <SwiperSlide
                      className="col-1 back-icon d-flex justify-content-end align-items-end p-2"
                      onClick={handleBackIconClick}
                    >
                      {!slideRight ? (
                        <IoChevronBackSharp />
                      ) : (
                        <MdArrowForwardIos />
                      )}
                    </SwiperSlide>
                    <div className="services_home">
                      {services &&
                        services.map((service, index) => ( // Null check before mapping over "services"
                          <SwiperSlide
                            key={index}
                            className="col-12 services_each_card col-2 p-2"
                          >
                            <div className="main-desc">
                              {" "}
                              <h6>
                                <b>{service.title}</b>
                              </h6>
                            </div>

                            <a
                              aria-label="hizmetlerimiz-link"
                              style={{ width: "100%" }}
                              href={`/hizmetlerimiz/${removeTurkishChars(
                                service.title
                              )
                                .replace(/\s+/g, "-")
                                .replace(/\&+/g, "ve")
                                .toLowerCase()}`}
                            >
                              {" "}
                              <button className="button-type p-2">
                                {service.buttonText}
                              </button>
                            </a>
                          </SwiperSlide>
                        ))}
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
