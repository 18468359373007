import React, { useEffect, useRef, useState } from "react";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import "../assets/css/faq.css";
import logo from "../assets/images/deka_logo.png";
import logo_faq from "../assets/images/faq-icon.png";
import { CiLocationArrow1 } from "react-icons/ci";
import faqData from "../data/faq.json";
import axios from "axios";
import { FaRegUser } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { Fade } from "react-reveal";
import {Helmet} from "react-helmet";

const FAQMain = () => {
  const faqs = faqData;
  const [showLoader, setShowLoader] = useState(true);
  const [chatWindowHeight, setChatWindowHeight] = useState("10vh");
  
  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  const [sourceId, setSourceId] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userAskedQuestion, setUserAskedQuestion] = useState(true);
  const inputRef = useRef(null);
  const [chatVisible, setChatVisible] = useState(true);
  const [chatContentVisible, setChatContentVisible] = useState(true);

  const handleCrossClick = () => {
    setChatHistory([]); 
    setUserAskedQuestion(true); 
    setChatWindowHeight("10vh"); 
  };

  const addAllPdfs = async () => {
    try {
      const pdfListUrl = "https://www.dekasolar.com/pdf/pdfs.txt";
  
      const response = await axios.get(pdfListUrl);
  
      const pdfUrls = response.data.split("\n");
  
      for (const pdfUrl of pdfUrls) {
        await addPdfByUrl(pdfUrl.trim());
      }
    } catch (error) {
      console.error("Error adding PDFs:", error.message);
    }
  };
  
  useEffect(() => {
    addAllPdfs();
  }, []);
  

  const addPdfByUrl = async (pdfUrl) => {
    try {
      const apiKey = "sec_9iDUZeDdRxcZo181cOK2f9LtFSrTnBQj";
      const apiUrl = "https://api.chatpdf.com/v1/sources/add-url";
  
      const response = await axios.post(
        apiUrl,
        { url: pdfUrl },
        { headers: { "x-api-key": apiKey } }
      );
      setSourceId(response.data.sourceId);
    } catch (error) {
      console.error("Error adding PDF:", error.message);
    }
  };
  
  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const sendMessage = async () => {
    if (!sourceId) {
      setLoadingMessage("Kaynak bekleniyor...");
      return;
    }
    setLoadingMessage("");

    const apiKey = "sec_9iDUZeDdRxcZo181cOK2f9LtFSrTnBQj";
    const apiUrl = "https://api.chatpdf.com/v1/chats/message";

    const data = {
      sourceId: sourceId,
      messages: [{ role: "user", content: userInput }],
    };

    try {
      setLoadingMessage("...");

      const response = await axios.post(apiUrl, data, {
        headers: { "x-api-key": apiKey },
      });

      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        { role: "user", content: userInput },
        { role: "assistant", content: response.data.content },
      ]);
      setUserInput("");
      setChatWindowHeight("50vh");
      setUserAskedQuestion(false);
    } catch (error) {
      console.error("Error chatting with PDF:", error.message);
      setErrorMessage("Bir hata oluştu. Lütfen tekrar deneyin.");
    } finally {
      setLoadingMessage("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <>
      <Helmet>
        <title>Sıkça Sorulan Sorular | Deka Solar</title>
        <meta name="description" content="Merak ettiklerinize hızlıca cevap bulun ya da DEKABOT’a sorun! Sorularınızı bizimle paylaşın, size en uygun enerji çözümleri konusunda sizlere yardımcı olalım."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/sikca-sorulan-sorular"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="d-flex justify-content-center">
            <div className="container">
              <div className="col-12 d-lg-flex gap-2 justify-content-center">
                <div className="col-lg-6 col-12 sss-bg-inner main-radius d-flex align-items-end ml-2">
                  <div className=" col-12 text-white text-start d-flex flex-column p-3 mt-5">
                    <h1>
                      <b>
                        Sıkça Sorulan <br />
                        Sorular
                      </b>
                    </h1>

                    <p>
                      Merak ettiklerinize hızlıca cevap bulun ya da DEKABOT’a
                      sorun! Sorularınızı bizimle paylaşın, size en uygun enerji
                      çözümleri konusunda sizlere yardımcı olalım.
                    </p>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-12 bg-white main-radius h-100 mt-lg-0 mt-3"
                  style={{
                    display: chatVisible ? "block" : "none",
                    overflowY: "auto",
                  }}
                >
                  {!userAskedQuestion && (
                    <div className="col-12 d-flex justify-content-end p-3">
                      <RxCross2
                        onClick={handleCrossClick}
                        style={{
                          color: "#EF3C26",
                          fontSize: "30px",

                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                  <div
                    className="chat-window mt-2"
                    style={{ height: chatWindowHeight }}
                  >
                    {chatContentVisible &&
                      chatHistory.map((message) => (
                        <div
                          key={message.content}
                          className={`d-flex flex-row message ${message.role}`}
                        >
                          <div className="col-2">
                            {message.role === "user" ? (
                              <FaRegUser
                                className="ms-3 me-4 user_icon"
                                style={{ color: "#EF3C26", width: "40px" }}
                              />
                            ) : (
                              <img
                                className="ms-3 me-3 chat_img"
                                src={logo_faq}
                                alt="Logo"
                              ></img>
                            )}
                          </div>
                          <div className="col-9">
                            <p className="text-start">{message.content}</p>
                          </div>
                        </div>
                      ))}
                    <div className="loading_message">
                      {loadingMessage && <p>{loadingMessage}</p>}
                    </div>
                    {errorMessage && (
                      <p className="error-message">{errorMessage}</p>
                    )}
                  </div>

                  {userAskedQuestion && (
                    <>
                      <div className="col-12  d-flex align-items-center justify-content-center flex-column faq_help">
                        <img className="w-20" src={logo} alt="Logo"></img>
                        <p className="mt-5">
                          Bugün size nasıl yardımcı olabiliriz?
                        </p>
                      </div>
                    </>
                  )}

                  <div className="col-12 p-2" style={{ position: "relative" }}>
                    <textarea 
                      className="mt-2 w-100 main-radius pl-4 faq-input"
                      type="text"
                      value={userInput}
                      onChange={handleUserInput}
                      onKeyDown={handleKeyDown}
                      ref={inputRef} 
                      rows="2" 
                                     
                    />
                    <div className="send-icon" onClick={sendMessage}>
                      <CiLocationArrow1 />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 bg-white mt-3 text-start p-3 main-radius mb-3">
                {faqs.map((faq) => (
                  <div key={faq.faq_no}>
                    <h5>
                      <b>{faq.title}</b>
                    </h5>
                    <p>{faq.desc}</p>
                  </div>
                ))}
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default FAQMain;
