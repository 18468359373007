import React, { useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { GiShakingHands } from "react-icons/gi";

const Basvuru = () => {
  const [isSuccessArray, setIsSuccessArray] = useState([false, false, false]);
  const [userInfo, setUserInfo] = useState("");
  const [tel, setTel] = useState("");
  const [subject, setSubject] = useState("İş Ortaklığı Başvurusu");
  const [from, setFrom] = useState("");
  const [message, setMessage] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const [additionalInfo, setAdditionalInfo] = useState({
    sayi: "",
    adres: "",
    tur: "",
    isim: ""
  });

  const handleNameInputChange = (e) => {
    setUserInfo(e.target.value);
  };
  const handleEmailInputChange = (e) => {
    setFrom(e.target.value);
  };
  const handleSubjectInputChange = (e) => {
    setSubject(e.target.value);
  };
  const handlePhoneInputChange = (e) => {
    setTel(e.target.value);
  };
  const handleMessageInputChange = (e) => {
    setMessage(e.target.value);
    // Ek bilgileri burada topla
    const additionalInfoArray = e.target.value.split("\n");
    setAdditionalInfo({
      sayi: additionalInfoArray[0] || "",
      adres: additionalInfoArray[1] || "",
      tur: additionalInfoArray[2] || "",
      isim: additionalInfoArray[3] || ""
    });
  };

  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClickButton = async (e, tabIndex) => {
    e.preventDefault();

    const encryptedText = CryptoJS.AES.encrypt(
      uuidv4(),
      process.env.REACT_APP_SSK
    ).toString();

    const FD = new FormData();
    FD.append("accessToken", encryptedText);
    FD.append("ownerName", "infodekasolar@gmail.com");
    FD.append("to", "info@dekasolar.com");
    FD.append("host", "smtp.gmail.com");
    FD.append("service", "gmail");
    FD.append(
      "message",
      `<p>Ad/Soyad:${additionalInfo.isim}</p>
       <p>İhtiyaç Detayı:${message}</p>
       <p>Adres:${additionalInfo.adres}</p>
       <p>Bir yıl içinde kurması planlanan istasyon sayisi:${additionalInfo.sayi}</p>
       <p>Şarj istasyonu kurulmak istenilen yerin türü:${additionalInfo.tur}</p>`
    );
    FD.append("tel", tel);
    FD.append("subject", subject);
    FD.append("from", from);
    FD.append("userInfo", userInfo);

    for (var pair of FD.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    try {
      const res = await axios.post("https://nodemailer.3wweb.org/", FD, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        const updatedSuccessArray = [...isSuccessArray];
        updatedSuccessArray[tabIndex] = true;
        setIsSuccessArray(updatedSuccessArray);
        setToastMessage("Mesajınız gönderildi. ✅"); 
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      setToastMessage("Mesajınız gönderilemedi. ❌");

    }
  };

  return (
    <>
      <div className="container text-header d-flex justify-content-center flex-column align-items-center contact-page">
        <div className="bg-main col-12 d-flex align-items-center justify-content-center flex-column">
          <div className="col-lg-10 col-12 mb-5 d-flex">
            <div className="text-black text-center w-100 p-3 mt-3">
              <h4><b>İş Ortaklığı Başvurusu</b></h4>
              <GiShakingHands style={{fontSize: "2rem", color: "#ef3c26"}} />
              <div className="bg-gray mt-4 p-2">
                <form ref={form} onSubmit={handleClickButton}>
                  <div className="">
                    <div className="col-12 mb-2">
                      <div className="form-group">
                        <input
                          type="text"
                          name="userInfo"
                          className="form-control"
                          placeholder="Firma/Kurum Adı"
                          required
                          data-error="Lütfen firma kurum adını giriniz"
                          onChange={handleNameInputChange}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="form-group">
                        <input
                          type="text"
                          name="isim"
                          id="isim"
                          className="form-control mb-2"
                          placeholder="Ad/Soyad"
                          onChange={(e) => setAdditionalInfo({...additionalInfo, isim: e.target.value})}
                          data-error="Lütfen doldurunuz!"
                        />
                        <input
                          type="text"
                          name="tel"
                          id="tel"
                          className="form-control mb-2"
                          placeholder="Telefon"
                          onChange={handlePhoneInputChange}
                          data-error="Lütfen doldurunuz!"
                        />
                        <input
                          type="text"
                          name="from"
                          id="from"
                          className="form-control"
                          placeholder="E-Posta"
                          onChange={handleEmailInputChange}
                          data-error="Lütfen doldurunuz!"
                        />
                        <input
                          type="text"
                          name="subject"
                          value={subject}
                          className="form-control d-none"
                          onChange={handleSubjectInputChange}
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="form-group">
                        <input
                          id="sayi"
                          type="text"
                          name="sayi"
                          onChange={(e) => setAdditionalInfo({...additionalInfo, sayi: e.target.value})}
                          className="form-control"
                          placeholder="Bir yıl içinde kurmayı planladığınız istasyon sayısı"
                          required
                          data-error="Lütfen doldurunuz!"
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <div className="col-12 mb-2">
                      <div className="form-group">
                        <input
                          id="tur"
                          type="text"
                          name="tur"
                          onChange={(e) => setAdditionalInfo({...additionalInfo, tur: e.target.value})}
                          className="form-control"
                          placeholder="Şarj istasyonu kurmak istediğiniz yerin türü"
                          data-error="lütfen doldurunuz!"
                        />
                        <div className="help-block with-errors" />
                      </div>
                    </div>
                    <textarea
                      type="text"
                      name="adres"
                      id="adres"
                      className="form-control mt-2"
                      rows={4}
                      onChange={(e) => setAdditionalInfo({...additionalInfo, adres: e.target.value})}
                      placeholder="Adresinizi giriniz"
                      required
                      data-error="lütfen doldurunuz!"
                    />
                    <textarea
                      type="text"
                      name="message"
                      id="message"
                      className="form-control mt-2"
                      rows={4}
                      onChange={handleMessageInputChange}
                      placeholder="Lütfen ihtiyaç ve talebinizi açıklayınız"
                      required
                      data-error="lütfen doldurunuz!"
                    />
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="button-type mt-2"
                        type="submit"
                        disabled={isSuccess}
                      >
                        Gönder
                      </button>
                    
                    </div>  {toastMessage && (
          <p className="mt-3">{toastMessage}</p>
        )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Basvuru;
