import React, { useEffect, useState } from "react";
import "../assets/css/enterprise.css";
import { Fade } from "react-reveal";
import noImgTwo from "../assets/images/img-2.png";
import img2 from "../assets/images/img-7.png"
import img3 from "../assets/images/img-4.png"
import img4 from "../assets/images/img.png"
import img5 from "../assets/images/img-1.png"
import img6 from "../assets/images/img-5.png"
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";
import {Helmet} from "react-helmet";

const ServicesMain = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  return (
    <>
      <Helmet>
        <title>Hizmetlerimiz | Deka Solar</title>
        <meta name="description" content="Enerji sektöründeki müşterilerimize kapsamlı hizmetler sunarak projelerinin her aşamasında güvenilir bir ortak oluyoruz."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/hizmetlerimiz"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container   justify-content-center align-items-center services-main">
            <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h_160 mt-5">
                <div className="col-lg-9 col-12 text-white d-flex flex-column justify-content-end p-3 mt-5 h_38">
                  <h1>
                    <b>Hizmetlerimiz</b>
                  </h1>
                  <h5>
                    <b>deka enerji olarak,</b>
                  </h5>{" "}
                  <p>
                    Enerji sektöründeki müşterilerimize kapsamlı hizmetler
                    sunarak projelerinin her aşamasında güvenilir bir 
                    ortak oluyoruz.
                  </p>
                </div>
                <div className="col-12 d-flex align-items-end flex-wrap p-3">
                  <div className="service-cards d-flex flex-wrap  gap-2 w-100 justify-content-lg-between">
                    <div className="services_each_card col-lg-2 col-5 p-3">
                      <h5>İşveren Mühendisliği</h5>

                      <a
                       
                        href="/hizmetlerimiz/isveren-muhendisligi"
                      >
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Fizibilite Raporu Hazılanması</h5>

                      <a  href="/hizmetlerimiz/fizibilite-raporu-hazilanmasi">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Denetim ve Sertifikasyon</h5>

                      <a href="/hizmetlerimiz/denetim-ve-sertifikasyon">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>İşletme & Bakım</h5>
                      <a
                        href="/hizmetlerimiz/isletme-ve-bakim"
                      >
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Santral Kıymetlendirme</h5>
                      <a
                        href="/hizmetlerimiz/santral-kiymetlendirme"
                      >
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Araç Şarj İstasyonları</h5>
                      <a
                        href="/hizmetlerimiz/arac-sarj-istasyonlari"
                      >
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12 mt-3">
            <div className="d-lg-flex gap-lg-2 justify-content-center ">
              <div className="col-lg-4 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start">
                  <img className="w-100" src={noImgTwo} alt="deka solar enerji"></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <b>İşveren Mühendisliği</b>
                    </h5>
                    <p>
                      İşveren mühendisliği, enerji projelerinde müşteri adına
                      mühendislik hizmetleri sağlamayı ifade eder. DEKA Enerji
                      olarak, müşterilerimizin Güneş Enerjisi Santralleri (GES)
                      projelerinde gereksinim duyduğu uzmanlık ve deneyimi
                      sağlayarak, projelerin sorunsuz bir şekilde ilerlemesine
                      katkıda bulunuyoruz.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start">
                  <img className="w-100" src={img2} alt="deka solar enerji"></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <b>Fizibilite Raporu Hazırlanması</b>
                    </h5>
                    <p>
                      Fizibilite raporu, bir enerji projesinin teknik, ekonomik
                      ve çevresel açılardan gerçekleştirilebilirliğini
                      değerlendiren bir belgedir. DEKA Enerji, müşterilerine
                      projelerinin başarılı bir şekilde hayata geçirilmesi için
                      gerekli olan bu kritik aşamada, kapsamlı ve doğru
                      fizibilite raporları hazırlamaktadır.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-lg-4 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start">
                  <img className="w-100" src={img3} alt="deka solar enerji"></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <b>Denetim ve Sertifikasyon</b>
                    </h5>
                    <p>
                      Denetim ve sertifikasyon, enerji projelerinin belirlenen
                      kalite standartlarına uygunluğunu değerlendirir. DEKA
                      Enerji, projelerin güvenilirlik ve kalite konularında
                      eksiksiz bir şekilde değerlendirilmesi için denetim ve
                      sertifikasyon süreçlerini etkin bir biçimde yönetir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex gap-lg-2 justify-content-center mt-3">
              <div className="col-lg-6 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-lg-flex">
                <div className="col-lg-6"><img className="w-100" src={img4} alt="deka solar enerji"></img></div>  
                  <div className="col-lg-6 p-2 d-flex flex-column justify-content-end">
                    {" "}
                    <h5>
                      <b>İşletme ve Bakım</b>
                    </h5>
                    <p>
                      İşletme ve bakım hizmetleri, enerji santrallerinin günlük
                      operasyonlarını yönetmeyi ve optimum performanslarını
                      sürdürmeyi içerir. DEKA Enerji, müşterilerine enerji
                      santrallerinin verimli işleyişini sağlamak ve uzun
                      ömürlülüklerini artırmak için profesyonel işletme ve bakım
                      hizmetleri sunar.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-lg-flex">
               <div className="col-lg-6">  <img className="w-100" src={img5} alt="deka solar enerji"></img></div> 
                  <div className="col-lg-6 p-2 d-flex flex-column justify-content-end">
                    {" "}
                    <h5>
                      <b>Santral Kıymetlendirme</b>
                    </h5>
                    <p>
                      Santral kıymetlendirme, enerji santrallerinin finansal
                      değerinin belirlenmesini içerir. DEKA Enerji,
                      müşterilerinin yatırımlarının en üst düzeye çıkarılması
                      için enerji santrallerinin ekonomik değerlendirmelerini
                      yapar ve bu alanda uzman danışmanlık sağlar.
                    </p>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="d-lg-flex mt-3 mb-3 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                 <div className="col-lg-4 d-flex align-items-center"><img className="w-100" src={img6} alt="deka solar enerji"></img></div> 
                  <div className="col-lg-8 p-2 d-flex justify-content-end flex-column">
                    {" "}
                    <h5 className="mt-5">
                      <b>Araç Şarj İstasyonları</b>
                    </h5>
                    <p>
                      DEKA Enerji olarak, enerji sektöründeki çeşitli
                      hizmetlerimize ek olarak, araç şarj istasyonları konusunda
                      da uzmanlığımızı sunmaktayız. Müşterilerimize, güneş
                      enerjisi santrallerinden araç şarj istasyonlarına kadar
                      geniş bir yelpazede hizmetler sunarak, sürdürülebilir
                      enerji çözümlerine katkıda bulunuyoruz. Bu kapsamda, araç
                      şarj istasyonlarının tasarımı, kurulumu ve bakımını
                      gerçekleştirerek müşterilerimizin ihtiyaçlarına özel
                      çözümler sunmaktayız.
                    </p>
              
                  </div>
                </div>
              </div>
            </div>
            <Footer className="mt-5" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ServicesMain;
