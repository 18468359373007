import React, { useEffect, useState } from "react";
import "../../assets/css/enterprise.css";
import "../../assets/css/services.css";
import { Fade } from "react-reveal";
import Footer from "../../wrappers/Footer";
import Header from "../../wrappers/Header";
import img1 from "../../assets/images/img_2.png";
import img2 from "../../assets/images/img_3.png";
import img3 from "../../assets/images/sarj_4.png";
import img4 from "../../assets/images/img_5.png";
import img5 from "../../assets/images/img_6.png";
import img6 from "../../assets/images/faq-icon.png";
import urun1 from "../../assets/images/urun-1.png";
import urun2 from "../../assets/images/urun-2.png";
import urun3 from "../../assets/images/urun3.png";
import isiklistant from "../../assets/images/hizli-2.jpg";
import hizliSarj from "../../assets/images/urun-4.png";
import kablo from "../../assets/images/urun-5.png"
import Basvuru from "../../wrappers/Basvuru";
import { FaCheckDouble } from "react-icons/fa";
import { RiBattery2ChargeLine } from "react-icons/ri";
import { FaRecycle } from "react-icons/fa";
import { MdMobileFriendly } from "react-icons/md";
import {Helmet} from "react-helmet";
import { ToastContainer, toast } from "react-toastify";



const AracSarj = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  return (
    <>
      <Helmet>
        <title>Araç Şarj İstasyonları | Deka Solar</title>
        <meta name="description" content="Unleashing a power-packed digital experience."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/hizmetlerimiz/arac-sarj-istasyonlari"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <ToastContainer/>
          <div className="container justify-content-center align-items-center services-main services-inner">
            <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h_160 mt-5">
                <div className="col-lg-9 col-12 text-white d-flex flex-column justify-content-end p-3 mt-5 h_38">
                  <h1>
                    <b>Hizmetlerimiz</b>
                  </h1>
                  <h5>
                    <b>deka enerji olarak,</b>
                  </h5>{" "}
                  <p>
                    Enerji sektöründeki müşterilerimize kapsamlı hizmetler
                    sunarak projelerinin her aşamasında güvenilir bir ortak
                    oluyoruz.
                  </p>
                </div>
                <div className="col-12 d-flex align-items-end flex-wrap p-3">
                  <div className="service-cards d-flex flex-wrap  gap-2 w-100 justify-content-between">
                    <div className="services_each_card col-lg-2 col-5 p-3">
                      <h5>İşveren Mühendisliği</h5>

                      <a href="/hizmetlerimiz/isveren-muhendisligi">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Fizibilite Raporu Hazılanması</h5>

                      <a href="/hizmetlerimiz/fizibilite-raporu-hazilanmasi">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Denetim ve Sertifikasyon</h5>

                      <a href="/hizmetlerimiz/denetim-ve-sertifikasyon">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>İşletme & Bakım</h5>
                      <a href="/hizmetlerimiz/isletme-ve-bakim">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Santral Kıymetlendirme</h5>
                      <a href="/hizmetlerimiz/santral-kiymetlendirme">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Araç Şarj İstasyonları</h5>
                      <a href="/hizmetlerimiz/arac-sarj-istasyonlari">
                        {" "}
                        <button className="services-inner-button-type-four">
                          daha fazla
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12 ">
            <h3 className="d-flex justify-content-start mt-4 mb-4">
              <b>Araç Şarj İstasyonları</b>
            </h3>
            <div className="d-lg-flex mt-3 mb-3 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-4">
                    <img
                      className="w-100"
                      src={img1}
                      alt="deka solar enerji"
                    ></img>
                  </div>
                  <div className="col-lg-8 p-2 d-flex justify-content-end flex-column">
                    <p className="m-0 p-0">
                      DEKA ENERJİ, Elektrikli Araç Şarj İstasyonu sektöründe
                      liderlik misyonunu taşıyarak, kurulum, işletme ve bakım
                      hizmetleriyle sektöre öncülük etmektedir. Aynı zamanda,
                      şarj istasyonlarından ticari kazanç elde etmeyi hedefleyen
                      gerçek ve tüzel kişilere, yatırım fırsatları sunarak
                      sektöre değer katan bir rol üstlenmektedir.
                    </p>
                    <p className="m-0 p-0">
                      &bull; DEKA Enerji t&uuml;m yatırımcılara, halka
                      a&ccedil;ık şarj istasyonlarına yatırım yapma ve bu
                      istasyonlardan gelir elde etme imkanı sunuyor.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Yatırım yaptığınız istasyonların detaylı kullanım
                      raporlarına, kullanımınıza a&ccedil;ılacak web portalı ile
                      ulaşabilir; kazan&ccedil;larınızı ger&ccedil;ek zamanlı
                      takip edebilirsiniz.
                    </p>
                    <p className="p-0 m-0">
                      {" "}
                      &bull; Yatırım yaptığınız tutar kadar şarj istasyonu
                      kurulumu yapıyoruz ve sizin adınıza işletiyoruz. Sizin
                      yada bizim belirlediğimiz lokasyonlara şarj istasyonlarını
                      yerleştiriyoruz.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-lg-flex gap-2 justify-content-center mb-lg-3">
              <div className="col-lg-3 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-flex flex-column justify-content-center align-items-center">
                  <div className="p-2 text-center">
                  <FaCheckDouble className="m-3"  style={{color:"#ef3c26", fontSize:"3rem"}}/>

                    <p>
                      İlk olarak, yatırım yapacağınız tutarı kendiniz
                      belirleyebilirsiniz ve danışmanlarımızdan destek
                      alabilirsiniz.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-flex flex-column justify-content-center align-items-center">
                  <div className="p-2 text-center ">
                  <RiBattery2ChargeLine className="m-3"  style={{color:"#ef3c26", fontSize:"3rem"}} />

                    <p>
                      Ardından, adınıza 5 yıllık bir sözleşme ile lokasyonları
                      keşfedip şarj istasyonlarını yerleştiriyor ve işletiyoruz.
                      Bu sayede, yatırımınızın yönetimi sizin adınıza
                      yapılmaktadır ve ekstra bir iş yükü almamanız
                      sağlanmaktadır.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-flex flex-column justify-content-center align-items-center">
                  <div className="p-2 text-center">
                  <FaRecycle className="m-3"  style={{color:"#ef3c26", fontSize:"3rem"}}/>

                    <p>
                      Oluşan geliri elektronik cüzdanınıza iletiyoruz ve size
                      düzenli gelir sağlama fırsatı sunuyoruz.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-flex flex-column justify-content-center align-items-center">
                  <div className="p-2 text-center">
                  <MdMobileFriendly className="m-3"  style={{color:"#ef3c26", fontSize:"3rem"}}/>

                    <p>
                      Tüm operasyonu mobil uygulama üzerinden takip
                      edebilirsiniz ve gelirlerinizi anlık olarak
                      görebilirsiniz. Bu sayede, yatırımınızın durumunu takip
                      etmek için ekstra bir çaba harcamanıza gerek kalmaz.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex gap-2 justify-content-center mb-lg-3">
              <div className="col-lg-6 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-flex flex-column">
                  <img src={img2} alt="deka solar enerji"></img>
                  <div className="p-2 ">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>Yer Sağlayıcı Modeli</b>
                      </div>{" "}
                    </h5>
                    <p>
                      İş yerinizin veya işletmenizin önüne, hiçbir maliyet
                      olmadan elektrikli araç şarj istasyonu kurulumunu
                      üstleniyoruz. Yer sağlayıcı olarak iş ortağımız
                      olduğunuzda, tüm şarj işlemlerinden elde edilen gelirin
                      %10’unu her ay hesabınıza yatırıyoruz. Ayrıca, iş
                      yerinizi, elektrikli araç sahipleri için çekici bir şarj
                      noktasına dönüştürüyor ve bu hizmetten yararlanmak
                      isteyenleri işletmenize yönlendiriyoruz.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-flex flex-column">
                  <img src={img3}></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>Yatırımcı Modeli</b>
                      </div>
                    </h5>
                    <p>
                      Bu iş ortaklığı modelimizde, elektrikli araç şarj
                      istasyonu kurulumu için gerekli tüm yatırım maliyeti
                      işletme sahipleri veya uygun bir konuma sahip olmayan,
                      ancak şarj istasyonlarına yatırım yapmayı düşünen
                      gerçek/tüzel kişiler tarafından gerçekleştirilir. Siz
                      yatırımcı olarak, şarj istasyonunun mülkiyetine sahip
                      olurken, işletmesini tarafımızca üstleniyoruz. Şarj
                      istasyonu üzerinden gerçekleşen tüm şarj işlemlerinden
                      elde edilen kazancın %80’ini her ay hesabınıza
                      aktarıyoruz. Bu modelde, yatırımcı olarak şarj
                      istasyonunun getirilerinden daha yüksek bir paya sahip
                      olursunuz. Aynı zamanda, bu modelde yatırım yapan işletme
                      ya da gerçek/tüzel kişiler, elektrikli araç
                      kullanıcılarına hizmet sunarak yeşil enerji alanında
                      sosyal sorumluluk da üstlenmiş olur.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-lg-flex gap-2 justify-content-center mb-lg-3">
              <div className="col-lg-5 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-flex flex-column justify-content-center">
                  <div className="p-2 ">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>Pasif Gelir Elde Etme</b>
                      </div>{" "}
                    </h5>
                    <p>
                      Şarj istasyonlarına yatırım yaparak, elektrikli araç
                      sahiplerinden gelen şarj ücretleriyle pasif bir gelir elde
                      edebilirsiniz.
                    </p>
                  </div>
                  <div className="p-2 ">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>Yatırımcı Karlılığı</b>
                      </div>{" "}
                    </h5>
                    <p>
                      Elektrikli araç kullanımının artmasıyla, şarj
                      istasyonlarına olan talep de artacaktır. Bu durum,
                      yatırımın orta ve uzun vadede karlı olmasını destekler.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-flex flex-column">
                  <img src={img4}></img>
                </div>
              </div>
            </div>

            <div className="d-lg-flex mt-lg-3 mb-3 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-4 d-flex">
                    {" "}
                    <img className="w-100" src={img5}></img>{" "}
                  </div>{" "}
                  <div className="col-lg-8 p-3 d-flex flex-column justify-content-end">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>
                          İşletmeler İçin Rekabet Avantajları
                        </b>
                      </div>{" "}
                    </h5>
                    <p className="m-0 p-0">
                   <b>Lokasyon Üstünlüğü:</b> Şarj
                      istasyonlarının kapasitesi belirli bir seviyeye
                      ulaştığında, yeni kurulum yapmak m&uuml;mk&uuml;n
                      olmayacaktır. Bu durum, işletmenizin bulunduğu lokasyonda
                      sınırlı sayıdaki şarj istasyonu işletmecilerinden biri
                      olmanızı sağlar.
                    </p>
                    <p className="m-0 p-0">
                   <b> Elektrikli Araç Kullanıcılarına Hizmet:</b>
                      İşletmeniz, şarj istasyonu olarak hizmet vererek,
                      elektrikli ara&ccedil; kullanıcılarının uğrak noktası
                      haline gelebilir. Bu da m&uuml;şteri potansiyelinizi
                      artırmanıza yardımcı olur.
                    </p>
                    <p className="m-0 p-0">
                     <b>Çevresel Duyarlılık</b> Elektrikli ara&ccedil;
                      şarj istasyonları işletmek, &ccedil;evre dostu bir hizmet
                      sunma taahh&uuml;d&uuml; ile m&uuml;şterilere
                      yenilik&ccedil;i ve &ccedil;evre bilincine sahip bir
                      işletme olduğunuzu g&ouml;sterir.
                    </p>
                    <p className="m-0 p-0">
                      <b>Marka İmajı Güçlendirme:</b>
                      &Ccedil;evresel etkiyi azaltma taahh&uuml;d&uuml;n&uuml;z,
                      işletmenizin marka imajını g&uuml;&ccedil;lendirir ve
                      m&uuml;şteri sadakatini artırır.
                    </p>
                    <p className="m-0 p-0">
                      <b>Geleceğin Sektörüne Yatırım Yapma:</b>
                      Elektrikli ara&ccedil; pazarının b&uuml;y&uuml;mesiyle
                      birlikte, şarj altyapısına yatırım yapmak, geleceğin
                      enerji sekt&ouml;r&uuml;nde &ouml;nc&uuml; olma şansını
                      sunar.
                    </p>
                    <p className="m-0 p-0">
                      <b>İşbirlikleri ve Ortaklıklar:</b> Şarj istasyonları
                      işletmek, enerji şirketleri, otomobil &uuml;reticileri
                      veya şehir y&ouml;netimleri gibi &ccedil;eşitli
                      sekt&ouml;rlerle işbirlikleri kurma fırsatı sunar. Bu,
                      işletmenizin geniş bir ağa entegre olmasını sağlar.
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="d-lg-flex mt-lg-3 mb-3 p-lg-0 p-1 arac-sarj-main">
              <div className="col-12 text-start mt-3">
                <h4>
                  <b>Ürünler ve Diğer Hizmetlerimiz</b>
                </h4>
                <div className="d-lg-flex gap-2 justify-content-center mb-lg-3 sarj-main">
                  <div className="col-lg-4 col-12 p-lg-0 p-1">
                    <div className=" text-start d-flex flex-column justify-content-center align-items-center">
                      <div className="p-5 ">
                        <img src={urun1}></img>
                        <h6 className="text-center card-type-two">
                          <b>AC 7 kW Şarj İstasyonu</b>
                        </h6>
                        <p className="text-center">
                          <b>$500 +KDV</b>{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-12 p-lg-0 p-1">
                    <div className="text-start d-flex flex-column justify-content-center align-items-center">
                      <div className="p-5">
                        <img src={urun2}></img>
                        <h6 className="text-center  card-type-two">
                          <b>AC 11 kW Şarj İstasyonu</b>
                        </h6>
                        <p className="text-center">
                          <b>$600 +KDV</b>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 p-lg-0 p-1">
                    <div className=" text-start d-flex flex-column justify-content-center align-items-center">
                      <div className="p-5 ">
                        <img src={urun3}></img>
                        <h6 className="text-center  card-type-two">
                          <b>AC 22 kW Şarj İstasyonu</b>
                        </h6>
                        <p className="text-center">
                          <b>$650 +KDV</b>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-lg-flex gap-2 justify-content-center mb-lg-3">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    {" "}
                    <img className="main-radius" src={isiklistant}></img>
                    <h2 className="text-center m-4">
                      <b>Işıklı ve Ekranlı Stant</b>
                    </h2>
                    <h3
                      className="p-2 main-radius w-100"
                      style={{ background: "#ef3c26", color: "white" }}
                    >
                      Teknik Özellikler
                    </h3>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Malzeme</td>
                          <td>
                            Paslanmaz Galvanizli Saç Üzerine elektrostatik Boya
                          </td>
                        </tr>
                        <tr>
                          <td>Net Ağırlık</td>
                          <td>15 kg</td>
                        </tr>
                        <tr>
                          <td>Boyut (GxDxY)</td>
                          <td>322x1481x130</td>
                        </tr>
                      </tbody>
                    </table>
                    <h3
                      className="p-2 main-radius w-100"
                      style={{ background: "#ef3c26", color: "white" }}
                    >
                      Çalışma Saatleri
                    </h3>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Çalışma Sıcaklığı</td>
                          <td>-35°C / +55°C</td>
                        </tr>
                        <tr>
                          <td>IK</td>
                          <td>IK10</td>
                        </tr>
                        <tr>
                          <td>IP</td>
                          <td>IP54</td>
                        </tr>
                        <tr>
                          <td>Nem</td>
                          <td>5%-95% Nispi Nem, Yoğunlaşmamış</td>
                        </tr>
                        <tr>
                          <td>Rakım</td>
                          <td>0-4000 m</td>
                        </tr>
                        <tr>
                          <td>Voltaj</td>
                          <td>400V AC, 50/60Hz, 3-Faz</td>
                        </tr>
                        <tr>
                          <td>Güç</td>
                          <td>7 kW</td>
                        </tr>
                        <tr>
                          <td>Koruma</td>
                          <td>Dahili DC 6mA RCD fonksiyonu</td>
                        </tr>
                        <tr>
                          <td>Voltaj</td>
                          <td>400V AC, 50/60Hz, 3-Faz</td>
                        </tr>
                        <tr>
                          <td>Akım</td>
                          <td>3x11A maksimum</td>
                        </tr>
                        <tr>
                          <td>Sertifikalar</td>
                          <td>
                            CE, TS EN 61851-21-2, TS EN 61851-22, TS EN 61851-1
                          </td>
                        </tr>
                        <tr>
                          <td>Soket Tipi</td>
                          <td>IEC 62196 Tip 2 EU</td>
                        </tr>
                      </tbody>
                    </table>
                    <h3
                      className="p-2 main-radius w-100"
                      style={{ background: "#ef3c26", color: "white" }}
                    >
                      Yazılım Özellikleri
                    </h3>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Bağlantı Tipi</td>
                          <td>Wifi, Bluetooth, Tak Çalıştır</td>
                        </tr>
                        <tr>
                          <td>Uzak Güncelleme</td>
                          <td>Wifi Modelinde Mevcut</td>
                        </tr>
                        <tr>
                          <td>Mobil Uygulama Kullanımı</td>
                          <td>Wifi ve Bluetooth’ lu Modellerde Mevcut</td>
                        </tr>
                        <tr>
                          <td>Uzaktan Veri İzleme</td>
                          <td>Var</td>
                        </tr>
                        <tr>
                          <td>Ağ Protokolü</td>
                          <td>OCPP 1.6 JSON, OCPP 2.0 JSON</td>
                        </tr>
                      </tbody>
                    </table>
                    <h3
                      className="p-2 main-radius w-100"
                      style={{ background: "#ef3c26", color: "white" }}
                    >
                      Diğer Özellikler
                    </h3>
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Ücretsiz Garanti Süresi</td>
                          <td>2 Yıl Garanti</td>
                        </tr>
                        <tr>
                          <td>Ücretsiz Nakliye</td>
                          <td>Yok</td>
                        </tr>
                        <tr>
                          <td>Ücretsiz Montaj</td>
                          <td>Yok</td>
                        </tr>
                      </tbody>
                    </table>
                    <h2 className="text-center m-4">
                      <b>AC Şarj İstasyonu Modelleri</b>
                    </h2>
                    <table class="table">
                      <thead>
                        <tr
                          className="border-table-first"
                          style={{ background: "#ef3c26", color: "white" }}
                        >
                          <th scope="col">Model</th>
                          <th scope="col">Satış Fiyatı</th>
                          <th scope="col">Açıklama</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ background: "#80808033" }}>
                          <td>
                            Duvar Tipi Tak Çalıştır Özellikli Type-2 AC Şarj
                            İstasyonu- 7 kW
                          </td>
                          <td>$500 +KDV</td>
                          <td>Kapalı Devre</td>
                        </tr>
                        <tr style={{ background: "#80808033" }}>
                          <td>
                            Duvar Tipi Tak Çalıştır Özellikli Type-2 AC Şarj
                            İstasyonu- 11 kW
                          </td>
                          <td>$600 +KDV</td>
                          <td>Kapalı Devre</td>
                        </tr>
                        <tr style={{ background: "#80808033" }}>
                          <td>
                            Duvar Tipi Tak Çalıştır Özellikli Type-2 AC Şarj
                            İstasyonu- 22 kW
                          </td>
                          <td>$650 +KDV</td>
                          <td>Kapalı Devre</td>
                        </tr>

                        <tr style={{ background: "#8080809e" }}>
                          <td>
                            Duvar Tipi Wifi Özellikli Online Type-2 AC Şarj
                            İstasyonu- 7 kW
                          </td>
                          <td>$750 +KDV</td>
                          <td>Açık Devre</td>
                        </tr>
                        <tr style={{ background: "#8080809e" }}>
                          <td>
                            Duvar Tipi Wifi Özellikli Online Type-2 AC Şarj
                            İstasyonu- 11 kW
                          </td>
                          <td>$850 +KDV</td>
                          <td>Açık Devre</td>
                        </tr>
                        <tr style={{ background: "#8080809e" }}>
                          <td>
                            Duvar Tipi Wifi Özellikli Online Type-2 AC Şarj
                            İstasyonu- 22 kW
                          </td>
                          <td>$900 +KDV</td>
                          <td>Açık Devre</td>
                        </tr>

                        <tr style={{ background: "gray" }}>
                          <td>
                            Duvar Tipi Bluetooth Özellikli Type-2 AC Şarj
                            İstasyonu- 7 kW
                          </td>
                          <td>$850 +KDV</td>
                          <td>Açık Devre</td>
                        </tr>
                        <tr style={{ background: "gray" }}>
                          <td>
                            Duvar Tipi Bluetooth Özellikli Type-2 AC Şarj
                            İstasyonu- 11 kW
                          </td>
                          <td>$950 +KDV</td>
                          <td>Açık Devre</td>
                        </tr>
                        <tr style={{ background: "gray" }}>
                          <td>
                            Duvar Tipi Bluetooth Özellikli Type-2 AC Şarj
                            İstasyonu- 22 kW
                          </td>
                          <td>$1000 +KDV</td>
                          <td>Açık Devre</td>
                        </tr>
                        <h5 className="m-3">
                          Sabitleme ayakları opsiyoneldir.
                        </h5>
                        <tr style={{ background: "#808080c4" }}>
                          <td>Led Işıklı Sabitleme Ayağı</td>
                          <td colSpan={2}>$250 +KDV</td>
                        </tr>
                        <tr style={{ background: "#808080c4" }}>
                          <td>Ekranlı Sabitleme Ayağı</td>
                          <td colSpan={2}>$270 +KDV</td>
                        </tr>

                        <tr style={{ background: "gray" }}>
                          <td>Kurulum</td>
                          <td colSpan={2}>$190 +KDV</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <p>
                  Araç şarj istasyonları ile ilgili diğer ürün ve hizmetlerimiz
                  hakkında daha detaylı bilgi almak için{" "}
                  <a
                    className="services-link"
                    href="/pdf/deka_sarj_istasyonu_katalog_baskı.pdf"
                    target="_blank"
                  >
                    tıklayın.
                  </a>
                </p>
                <h5>
                  <b>Kapalı Devre Sarj İstasyonu</b>
                </h5>
                <p className="m-0 p-0">
                  Şarj soketi takıldığında şarj işleminin otomatik olarak
                  başladığı ve şarj ağına dâhil olmayan bireysel kullanıcıların
                  kullanımına sunulan istasyon türüdür.
                </p>
                <h5 className="mt-3">
                  <b>Açık Devre Sarj İstasyonu</b>
                </h5>
                <p className="m-0 p-0">
                  Şarj işleminin mobil uygulama üzerinden kontrol edildiği ve
                  şarj istasyonundan herkesin istifade edebildiği şarj ağına
                  dâhil olan istasyon türüdür.
                </p>
                <div className="d-flex justify-content-start align-items-center mt-3">
                  <img className="me-3" src={img6}></img>

                  <b>
                    Xşarj Şarj İstasyonları, elektrikli araçlarınızı hızlı,
                    güvenli ve etkili bir şekilde şarj etmek için tasarlanmış
                    bir üründür. Bu şarj istasyonu, ev veya ofis gibi yerlerde
                    kullanılmak üzere tasarlanmıştır ve üstün performans ve
                    kullanım kolaylığı sunar.
                  </b>
                </div>{" "}
                <div className="d-flex justify-content-start align-items-center mt-3">
                  <img className="me-3" src={img6}></img>

                  <b>
                    Xşarj Şarj İstasyonları, tüm elektrikli araçlarla uyumlu
                    olan standart bir Type 2 konektörüne sahiptir. Bu konektör,
                    birçok farklı marka ve modeldeki araçlarla uyumludur ve
                    geniş bir kullanım alanı sağlar.
                  </b>
                </div>{" "}
                <div className="d-flex flex-column align-items-center justify-content-center">
                  {" "}
                  <img className="main-radius" src={hizliSarj}></img>
                  <h2 className="text-center m-4">
                    <b>Hızlı Şarj İstasyonu DC 120 kW</b>
                  </h2>
                  <h4>$25000 +KDV</h4>
                </div>
                <div className="text-center">
                  {" "}
                  <h5 className="text-center m-4">
                    <b>Sarj İstasyonu DC Modeli</b>
                  </h5>
                  <p>
                    Yüksek Hızlı Şarj imkanı sağlayabilen DC şarj istasyonu,
                    120kW güç özelliğiyle sunulmaktadır.
                  </p>
                  <h5>
                    <b>DC Şarj İstasyonu Genel Özellikleri</b>
                  </h5>
                  <div>&bull; &Uuml;retim hatalarına karşı 2 yıl garanti.</div>
                  <div>
                    &bull; Aracınızı y&uuml;ksek hızda ve g&uuml;venle şarj
                    eder.
                  </div>
                  <div>
                    &bull; 120 kW DC maksimum g&uuml;&ccedil; ile ultra
                    y&uuml;ksek hızlarda şarj imkanı
                  </div>
                  <div>&bull; Soket: DC-HP CCS</div>
                  <div>&bull; Kablo uzunluğu: 4.5 m</div>
                  <div>&bull; Ekran: LCD Dokunmatik Ekran</div>
                  <div>&bull; Bağlantı: Ethernet, Wi-Fi and LTE</div>
                  <div>&bull; OCPP 1.6J</div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                  {" "}
                  <img className="main-radius" src={kablo}></img>
                  <h2 className="text-center m-4">
                    <b>Şarj Kablosu Tip2 / 2.7m / 32A / 22 kW</b>
                  </h2>
                  <h4>$250 +KDV</h4>
                  <div className="mt-3">  <div>&bull; Uzunluk: 2.7 Metre</div>
                  <div>&bull; Malzeme: Sert kauçuk dayanıklı</div>
                  <div>&bull; Ergonomik tasarım kolay kullanım.</div>
                  <div>&bull; Üretim hatalarına karşı 2 Yıl Garanti</div></div>
                
                  
                </div>
              </div>
            </div>
            <Basvuru />
            <Footer className="mt-5" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default AracSarj;
