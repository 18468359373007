import React, { useEffect, useState } from "react";
import "../../assets/css/enterprise.css";
import "../../assets/css/services.css";
import { Fade } from "react-reveal";
import Footer from "../../wrappers/Footer";
import Header from "../../wrappers/Header";
import img1 from "../../assets/images/fizibilite2.png";
import img6 from "../../assets/images/faq-icon.png";
import {Helmet} from "react-helmet";

const Fizibilite = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  return (
    <>
      <Helmet>
        <title>Fizibilite Raporu Hazırlanması | Deka Solar
</title>
        <meta name="description" content="Unleashing a power-packed digital experience."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/hizmetlerimiz/fizibilite-raporu-hazilanmasi"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container justify-content-center align-items-center services-main services-inner">
            <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h_160 mt-5">
                <div className="col-lg-9 col-12 text-white d-flex flex-column justify-content-end p-3 mt-5 h_38">
                  <h1>
                    <b>Hizmetlerimiz</b>
                  </h1>
                  <h5>
                    <b>deka enerji olarak,</b>
                  </h5>
                  <p>
                    Enerji sektöründeki müşterilerimize kapsamlı hizmetler
                    sunarak projelerinin her aşamasında güvenilir bir ortak
                    oluyoruz.
                  </p>
                </div>
                <div className="col-12 d-flex align-items-end flex-wrap p-3">
                  <div className="service-cards d-flex flex-wrap  gap-2 w-100 justify-content-between">
                    <div className="services_each_card col-lg-2 col-5 p-3">
                      <h5>İşveren Mühendisliği</h5>

                      <a href="/hizmetlerimiz/isveren-muhendisligi">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Fizibilite Raporu Hazılanması</h5>

                      <a href="/hizmetlerimiz/fizibilite-raporu-hazilanmasi">
                        <button className="services-inner-button-type-four">
                          daha fazla
                        </button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Denetim ve Sertifikasyon</h5>

                      <a href="/hizmetlerimiz/denetim-ve-sertifikasyon">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>İşletme & Bakım</h5>
                      <a href="/hizmetlerimiz/isletme-ve-bakim">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Santral Kıymetlendirme</h5>
                      <a href="/hizmetlerimiz/santral-kiymetlendirme">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Araç Şarj İstasyonları</h5>
                      <a href="/hizmetlerimiz/arac-sarj-istasyonlari">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12 ">
            <h3 className="d-flex justify-content-start mt-4 mb-4">
              <b> Fizibilite Raporu Hazırlanması</b>
            </h3>
            <div className="d-lg-flex mt-3 mb-3 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-3">
                    <img className="w-100" src={img1}></img>
                  </div>
                  <div className="col-lg-9 p-2 d-flex justify-content-end flex-column">
                    <p className="m-0 p-0">
                      DEKA Enerji, yatırımcılar adına özenle hazırladığı
                      İngilizce ve Türkçe fizibilite raporlarıyla, güneş
                      enerjisi santrali projelerini hayata geçirme yolunda bir
                      rehberlik sunmaktadır. Bu süreç, yüksek çözünürlüklü
                      kameralarla donatılmış droneların kullanımı ve gelişmiş
                      görüntü işleme yazılımlarıyla sahanın 3D haritasının
                      detaylı bir şekilde çıkarılmasıyla başlamaktadır. Elde
                      edilen 3D harita, PVSYST üzerinde gerçekleştirilecek
                      analizlerde kullanılarak, projenin teknik ve finansal
                      ayrıntıları en ince detayına kadar incelenir. NBD, ROI ve
                      IRR analizleri ile birlikte, projenin güçlü ve zayıf
                      yanlarını belirleyen SWOT, PESTEL, LCOE analizleri gibi
                      unsurlar, yatırımcının projeye olan bakışını netleştirir.
                      Ayrıca, ruhsatlandırma sürecinde mevzuata uygunluk
                      kontrolü ve olası risklerin önceden belirlenmesi, projenin
                      gelecekte karşılaşabileceği potansiyel zorluklara
                      hazırlıklı olunmasını sağlar. Bu raporlar, sadece teknik
                      ve finansal analizlere odaklanmakla kalmaz, aynı zamanda
                      projenin özünü ve duygusunu yansıtarak yatırımcılara
                      güvenilir bir yol gösterici sunar.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Footer className="mt-3" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Fizibilite;
