
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/enterprise.css";
import { Fade } from "react-reveal";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import expData from "../data/experience.json";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import {Helmet} from "react-helmet";

const ExperienceSingleMain = () => {
  const { title } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 2500);
  }, []);

  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }

  const expPost = expData.find(
    (experience) =>
      removeTurkishChars(experience.title)
        .replace(/\s+/g, "-")
        .toLowerCase() === title
  );

  if (!expPost) {
    return <p>Experience not found</p>;
  }

  const parseHTML = (array) => {
    return array.map((item) => item);
  };
  const locations = parseHTML(expPost.location);
  const types = parseHTML(expPost.type);

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
  };

  console.log("Locations:", locations);

  console.log("Types:", types);

  return (
    <>
    <Helmet>
    <title>{typeof expPost.title === 'string' ? expPost.title : ''} | Deka Solar </title>
        <meta name="description" content={typeof expPost.desc_seo === 'string' ? expPost.desc_seo : ''}/>
        <link
          rel="canonical"
          href={`https://www.dekasolar.com/deneyimlerimiz/${removeTurkishChars(
            expPost.title
          )
            .replace(/\s+/g, "-")
            .toLowerCase()}`}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div>
          <h1 className="d-none"><b>{expPost.title}</b></h1>

            <div className="container  d-flex justify-content-center align-items-center">
              <div className="experince-single container d-flex justify-content-center align-items-center p-0">
                <div className="col-lg-11 col-12 d-flex align-items-center justifyc-content-center flex-column">

                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    loop={true}
                    onSlideChange={handleSlideChange}
                  >
                    {expPost.img_main.map((imgSrc, index) => (
                      <SwiperSlide key={index}>
                        <div className="d-flex justify-content-center align-items-center flex-column container">
                          <div className="col-lg-10 col-12">
                            <img src={imgSrc} alt={`experience-${index}`} />
                          </div>
                          <div className="col-12">
                            <h4 className="text-start mt-3 mb-3">
                              <b>{expPost.title}</b>
                            </h4>
                            <div className="d-flex">
                              <div className="col-6 text-start">
                                <p>
                                  <b>{expPost.title_inner}</b>
                                </p>
                                <p>{locations[index % locations.length]}</p>
                              </div>
                              <div className="col-6 text-start">
                                <p>
                                  <b>{expPost.title_inner_2}</b>
                                </p>
                                <p>{types[index % types.length]}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="container col-12 p-3">
              <Footer />
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ExperienceSingleMain;
