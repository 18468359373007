import React from "react";
import "../assets/css/enterprise.css";
import noImg from "../assets/images/kurumsal-deka.png";
import Header from "../wrappers/Header";
import ScrollToTop from "./ScrollToTop";

const Enterprise = () => {
  return (
    <>
      <div className="scroll-to-top">
        <ScrollToTop />
      </div>
      <Header />
      <div className="d-flex justify-content-center m-2">
        <div className="container bg-white enterprise-main d-flex justify-content-center align-items-center">
          <div className="container inner-container bg-enterprise d-flex justify-content-center align-items-center p-0">
            <div className="col-12 d-flex align-items-end text-start h-100">
              <div className="col-lg-6 col-12 bg-white d-flex text-black align-items-center enterprise-radius">
                <div className="col-lg-4 p-lg-4 col-md-4 col-5 p-2">
                  <img src={noImg} alt="deka solar enerji"></img>
                </div>
                <div className="col-lg-7 col-7 col-md-7 p-2">
                  <h5>
                    <b>Kurumsal</b>
                  </h5>
                  <p>
                    Güneş enerji santralleri sektöründe uzman bir ekip olarak,
                    müşterilere işveren mühendisliği, fizibilite raporu, santral
                    değerleme, bakım-onarım, test, denetim ve araç şarj
                    istasyonları gibi birçok hizmet sunmaktayız.
                  </p>
                  <a href="/kurumsal" aria-label="kurumsal-link">
                    {" "}
                    <button className="button-type">daha fazla</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Enterprise;
