import React from "react";
import "../assets/css/enterprise.css";
import noImg from "../assets/images/makaleler.png";
import blogsData from "../data/blog.json";
import ScrollToTop from "./ScrollToTop";

const Articles = () => {
  const lastBlog = blogsData[blogsData.length - 1];
  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }
  return (
    <>
      <div className="scroll-to-top">
        <ScrollToTop />
      </div>
      <div className="d-flex justify-content-center m-2 articles-page">
        <div className="container bg-white enterprise-main d-flex justify-content-center align-items-center">
          <div className="bg-light container inner-container bg-articles d-flex justify-content-center align-items-center p-0">
            <div className="col-12  text-start h-100 d-flex align-items-end flex-lg-row flex-column justify-content-end">
              <div className="col-lg-6 col-12 d-flex text-black align-items-center text-white p-4">
                <div className="card-type-four bg-articles-card main-radius d-flex p-4">
                  <div className="col-lg-4 p-lg-4 col-5 p-2 d-flex align-items-center">
                    <img
                      className="main-radius"
                      src={lastBlog.img}
                      alt="deka solar enerji"
                    ></img>
                  </div>
                  <div className="col-lg-7 col-7 p-2 d-flex justify-content-center flex-column ">
                    <p>
                      <b>{lastBlog.title}</b>
                    </p>
                    <a
                      aria-label="blog-link"
                      href={`/makaleler/${removeTurkishChars(lastBlog.title)
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                    >
                      <button className="button-type">daha fazla</button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12 bg-white d-flex text-black align-items-center articles-radius">
                <div className="col-lg-4 p-lg-4 col-5 p-2">
                  <img src={noImg} alt="deka solar enerji"></img>
                </div>
                <div className="col-lg-7 col-7 p-2">
                  <h5>
                    <b>Makaleler</b>
                  </h5>
                  <p>
                    DEKA Enerji, sürdürülebilir enerji geleceğine katkıda
                    bulunan ve teknoloji ile çevreyi birleştiren projeleriyle
                    öne çıkıyor. Detaylı bilgi ve güncel projeler için
                    makalelerimizi keşfedin.
                  </p>
                  <a href="/makaleler" aria-label="blog-main-link">
                    <button className="button-type">tüm makaleler</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
