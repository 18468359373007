import React, { useEffect, useState } from "react";
import "../../assets/css/enterprise.css";
import "../../assets/css/services.css";
import { Fade } from "react-reveal";
import Footer from "../../wrappers/Footer";
import Header from "../../wrappers/Header";
import img1 from "../../assets/images/img_.png";
import img2 from "../../assets/images/img-11.png";
import img3 from "../../assets/images/img-12.png";
import img4 from "../../assets/images/img-13.png";
import img5 from "../../assets/images/img-14.png";
import { IoMdClose } from "react-icons/io";
import { IoMdCheckmark } from "react-icons/io";
import img6 from "../../assets/images/faq-icon.png";
import {Helmet} from "react-helmet";

const Isletme = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  return (
    <>
      <Helmet>
        <title>İşletme & Bakım | Deka Solar
</title>
        <meta name="description" content="Unleashing a power-packed digital experience."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/hizmetlerimiz/isletme-ve-bakim"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container justify-content-center align-items-center services-main services-inner">
            <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h_160 mt-5">
                <div className="col-lg-9 col-12 text-white d-flex flex-column justify-content-end p-3 mt-5 h_38">
                  <h1>
                    <b>Hizmetlerimiz</b>
                  </h1>
                  <h5>
                    <b>deka enerji olarak,</b>
                  </h5>{" "}
                  <p>
                    Enerji sektöründeki müşterilerimize kapsamlı hizmetler
                    sunarak projelerinin her aşamasında güvenilir bir ortak
                    oluyoruz.
                  </p>
                </div>
                <div className="col-12 d-flex align-items-end flex-wrap p-3">
                  <div className="service-cards d-flex flex-wrap  gap-2 w-100 justify-content-between">
                    <div className="services_each_card col-lg-2 col-5 p-3">
                      <h5>İşveren Mühendisliği</h5>

                      <a href="/hizmetlerimiz/isveren-muhendisligi">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Fizibilite Raporu Hazılanması</h5>

                      <a href="/hizmetlerimiz/fizibilite-raporu-hazilanmasi">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Denetim ve Sertifikasyon</h5>

                      <a href="/hizmetlerimiz/denetim-ve-sertifikasyon">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>İşletme & Bakım</h5>
                      <a href="/hizmetlerimiz/isletme-ve-bakim">
                        {" "}
                        <button className="services-inner-button-type-four">
                          daha fazla
                        </button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Santral Kıymetlendirme</h5>
                      <a href="/hizmetlerimiz/santral-kiymetlendirme">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Araç Şarj İstasyonları</h5>
                      <a href="/hizmetlerimiz/arac-sarj-istasyonlari">
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12">
            <h3 className="d-flex justify-content-start mt-4 mb-4">
              <b> İşletme & Bakım </b>
            </h3>
            <div className="d-lg-flex mt-3  p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="p-2 ">
                    {" "}
                    <h5></h5>
                    <p>
                      DEKA ENERJİ olarak, Güneş Enerjisi Santrallerinin (GES)
                      işletme ve bakımında uzman bir ekiple hizmetinizdeyiz. GES
                      tesisinizin verimliliğini ve ömrünü maksimum seviyede
                      tutmak için sürekli kontrol ve izleme süreçlerimizle,
                      profesyonel bir ekip tarafından destekleniyorsunuz. Bizim
                      sunmuş olduğumuz bakım hizmetleri, GES tesisinizin
                      parçalarının düzenli kontrol ve bakımını içerir. Teknik
                      uzmanlarımızın 7/24 hizmet verdiği bu süreç, elektrik
                      üretiminin maksimum seviyede gerçekleşmesini sağlamak,
                      verimlilik analizleri yapmak ve sisteminizi en iyi şekilde
                      çalıştırmak amacıyla uygulanır. Profesyonel bakım, olası
                      arızaları önceden tespit ederek uzun vadeli maliyetleri
                      minimize etmeyi hedefler. Tesisinizin sorunsuz ve
                      dayanıklı bir şekilde çalışmasını sağlamak için bakım
                      süreçlerimiz özenle tasarlanmıştır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex mt-3  p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-3 d-flex justify-content-center">
                    <img className="w-100" src={img2}></img>
                  </div>
                  <div className="col-lg-9 p-3 d-flex justify-content-end flex-column">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>
                          İzleme ve <br />
                          Analiz Hizmetleri{" "}
                        </b>
                      </div>
                    </h5>
                    <p className="m-0 p-0">
                      DEKA Enerji, güneş enerji santrallerinde anlık
                      üretim-tüketim takibi ve verimlilik analizi konularında
                      uzmanlaşmıştır. Profesyonel, tecrübeli teknik personeli ve
                      yapay zeka tabanlı SOLARİFY izleme yazılımı sayesinde
                      dünyanın dört bir yanından:
                    </p>
                    <p className="m-0 p-0">
                      &bull; Santral Anlık Verilerine Erişim: G&uuml;neş enerji
                      santralinizin anlık verilerine her an erişim sağlama.
                    </p>
                    <p className="m-0 p-0">
                      &bull; &Uuml;retim Karşılığı Kazancı G&ouml;zleme:
                      Ger&ccedil;ekleşen &uuml;retimle kazan&ccedil;ları anlık
                      olarak takip etme.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Ger&ccedil;ek ve Tahmini &Uuml;retim Değerlerini
                      Karşılaştırma: G&uuml;nl&uuml;k, haftalık, aylık ve yıllık
                      periyotlarda ger&ccedil;ek ve tahmini &uuml;retim
                      değerlerini karşılaştırma imkanı.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Santral Performansını Değerlendirme: G&uuml;neş
                      enerji santralinin performansını detaylı bir şekilde
                      değerlendirme.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Anlık Arıza Tespiti: Santralde oluşan arızaları
                      anlık olarak tespit etme ve m&uuml;dahale etme.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Analiz Raporları Oluşturma: Yapılan analizler
                      sonucu (g&uuml;nl&uuml;k, haftalık, aylık ve yıllık) rapor
                      oluşturabilme imk&acirc;nı.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex mt-3  p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-9 p-2 d-flex justify-content-end flex-column">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>
                          Bakım ve <br />
                          Onarım Hizmetleri{" "}
                        </b>
                      </div>
                    </h5>
                    <p className="m-0 p-0">
                      &bull; Arıza Tespiti: &Uuml;retimi etkileyebilecek
                      arızaların tespiti ve saha personeline anlık bildirim.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Koruyucu Bakım: Kontrol edilmesi gereken
                      donanımların belirlenmesi ve aylık/yıllık bakım
                      planlarının oluşturulması.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Uyarıcı Bakım: Verilerin değerlendirilerek, olası
                      arızalara m&uuml;dahale etme &ouml;ncesi uyarı sisteminin
                      devreye alınması.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Mevsimsel Bakım: Mekanik ve elektriksel
                      donanımların mevsim koşullarına uygun incelenmesi ve
                      bakımlarının ger&ccedil;ekleştirilmesi.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Stok Y&ouml;netimi: &Uuml;retim kaybını en aza
                      indirmek i&ccedil;in gerekli yedek par&ccedil;aların
                      belirlenmesi ve stok y&ouml;netiminin yapılması.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Performans İncelemesi: PV panel degradasyon testi,
                      PV panel kirlilik kaybı testi ve IV-Curve analizleri ile
                      santral performans raporlaması yapılması.
                    </p>
                  </div>{" "}
                  <div className="col-lg-3">
                    {" "}
                    <img className="w-100" src={img3}></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex mt-3  p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-3">
                    <img className="w-100" src={img4}></img>
                  </div>
                  <div className="col-lg-9 p-2 d-flex justify-content-end flex-column">
                    {" "}
                    <h5 className="mt-5">
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>
                          İşletme <br />
                          Yönetimi Hizmetleri{" "}
                        </b>
                      </div>
                    </h5>
                    <p className="m-0 p-0">
                      &bull; Sigorta Takibi: Sigorta şirketleri ile iletişim,
                      poli&ccedil;e s&uuml;re&ccedil;leri, arıza ve kayıp
                      durumlarında sigorta şirketine başvuru yapılması.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Tahsilat İşlemleri: Faturalandırma ve
                      &ouml;demelerin takibi, EDAŞ/TEDAŞ &ouml;demelerinin
                      kontrol&uuml;.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Garanti S&uuml;reci Y&ouml;netimi: Arıza durumunda
                      &uuml;retici firma ile iletişim kurularak garanti
                      s&uuml;recinin y&ouml;netilmesi.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Stok Kontrol&uuml;: Yedek malzeme listesinin
                      hazırlanması, satın alma ve lojistik
                      s&uuml;re&ccedil;lerinin y&ouml;netilmesi.
                    </p>
                    <p className="m-0 p-0">
                      &bull; İş G&uuml;venliği: İş sağlığı ve g&uuml;venliği
                      &ouml;nerilerinin sunulması ve rapor hazırlanması.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Enerji T&uuml;ketimi Kontrol&uuml;: T&uuml;ketim
                      tesisinin enerji kontrol&uuml;n&uuml;n yapılması.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex mt-3  p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex align-items-end">
                  <div className="p-2 col-lg-9">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>
                          Performans <br />
                          İncelemesi Hizmeti{" "}
                        </b>
                      </div>
                    </h5>
                    <p className="m-0 p-0">
                      Test, denetim ve ölçüm hizmetleri kapsamında
                      gerçekleştirilen işlemlerin değerlendirilmesi ve
                      performans incelemesi:
                    </p>
                    <p className="m-0 p-0">
                      &bull; Termal Kameralı Drone İncelemesi: Sahanın
                      termografik haritasının &ccedil;ıkarılması.
                    </p>
                    <p className="m-0 p-0">
                      &bull; IV-Curve Analizi: Panellerin performansının
                      değerlendirilmesi.
                    </p>
                    <p className="m-0 p-0">
                      &bull; PV Panel Degradasyon Testi: Panel degradasyon
                      testinin ger&ccedil;ekleştirilmesi.
                    </p>
                    <p className="m-0 p-0">
                      &bull; PV Panel Kirlilik Kaybı Testi: Panel kirlilik kaybı
                      testinin yapılması.
                    </p>
                    <p className="m-0 p-0">
                      &bull; PV Panel G&ouml;lgelenme Analizi: G&ouml;lgelenme
                      analizi ile santral performansının değerlendirilmesi.
                    </p>
                  </div>
                  <div className="col-lg-3">
                    {" "}
                    <img className="w-100" src={img5}></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex mt-3 mb-3 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-3">
                    <img className="w-100" src={img1}></img>
                  </div>{" "}
                  <div className="col-lg-9 p-2 d-flex justify-content-end flex-column">
                    {" "}
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>
                          Güvenlik <br />
                          Hizmetleri{" "}
                        </b>
                      </div>
                    </h5>
                    <p className="m-0 p-0">
                      Saha güvenliği için kurulum ve izleme hizmetlerinin
                      sağlanması:
                    </p>
                    <p className="m-0 p-0">
                      &bull; Kapalı Devre Kamera Sistemi Kurulumu: G&uuml;neş
                      enerji santrali alanına kapalı devre kamera sistemi
                      kurulumu ve devreye alınması.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Saha İncelemesi: Santral alanının kapalı devre
                      kamera sistemi ile incelenmesi.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Olay Bildirim Sistemi: Saha alanının dış kaynaklı
                      risklere maruz kalması durumunda yetkililere ve
                      g&uuml;venlik g&uuml;&ccedil;lerine bildirim yapılması.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">basic</th>
                    <th scope="col">standart</th>
                    <th scope="col">premium</th>
                    <th scope="col">elite</th>
                    <th scope="col">silver</th>
                    <th scope="col">gold</th>
                    <th scope="col">platine</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">polarize testleri</th>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">pv dizi doc ısc ölçümleri</th>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">i-v curve analizi</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">topraklama süreklilik testleri</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">bağlantı elemanları tork kontrolü</th>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">izolasyon testleri</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">ac-dc ekipman fonksyion testleri</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">santral performans testleri</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">trafo-og hücre bakım, test ve kontrolü</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">saha uzaktan izleme ve günlük raporlama</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">denetim, bakım, onarım raporlama</th>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">sahada elektolüminesans (el ) testi</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">arıza tespit ve onarım hizmeti</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">saha temizliği (ot, haşere vb.)</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">panel temizliği</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">og-ag elektrik panoları temizliği</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">teknik servis yönetimi</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">yedek parça yönetimi ve stok kontrolü</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">edaş/tedaş ile koordinasyon</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">fatura takip edaş/tedaş ödemeleri</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      edaş/tedaş/epdk ile ilgili işlemlerin yönetimi
                    </th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">garanti işlemleri yönetimi</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">sigorta sözleşmeleri yönetimi</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">iş sağlığı ve güvenliği danışmanlığı</th>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdClose />
                    </td>
                    <td>
                      <IoMdCheckmark />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Footer />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Isletme;
