import React, { useEffect } from "react";
import "../assets/css/enterprise.css";
import "../assets/css/experience.css";
import noImg from "../assets/images/deneyim.png";
import { Fade } from "react-reveal";
import Header from "../wrappers/Header";
import Marquee from "react-fast-marquee";
import partnersData from "../data/partners.json"; // Ayarlayın
import osiImage from "../assets/images/osi.svg"; // Import the image
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const Experience = () => {
  return (
    <>
      {" "}
      <div className="scroll-to-top">
        {" "}
        <ScrollToTop />
      </div>
      <div className="d-flex justify-content-center m-2">
        <div className="container bg-white enterprise-main d-flex justify-content-center align-items-center">
          <div className="bg-light container inner-container bg-experience d-flex justify-content-center align-items-center p-0">
            <div className="col-12  text-start h-100">
              <div className="col-lg-6 col-12 bg-white d-flex text-black align-items-center experience-radius">
                <div className="col-lg-4 col-md-4 p-lg-4 col-5 p-2">
                  <img src={noImg} alt="deka solar enerji"></img>
                </div>
                <div className="col-lg-7 col-md-7 col-7 p-2">
                  <h5>
                    <b>Deneyimlerimiz</b>
                  </h5>
                  <p>
                    Deka Enerji, sektörde edindiği zengin deneyim ve birikimle
                    araç şarj istasyonları alanında öne çıkan bir uzmanlık
                    sunmaktadır. Yenilikçi Ar-Ge projeleri ve çeşitli
                    ölçeklerdeki araç şarj istasyonu projelerinde elde ettiğimiz
                    başarılar, müşterilerimize çevre dostu ve etkili enerji
                    çözümleri sunmamızı sağlamaktadır.
                  </p>
                  <a href="/deneyimlerimiz" aria-label="deneyim-link">
                    <button className="button-type">daha fazla</button>
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-12 col-12 text-white d-flex flex-column justify-content-start p-3">
                  <Marquee style={{ padding: "10vh" }}>
                    {partnersData.partners.map((partner) => (
                      <img
                        style={{ height: "15vh" }}
                        key={partner.no}
                        src={osiImage}
                        alt={partner.title}
                      />
                    ))}
                  </Marquee>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
