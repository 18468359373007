import React from "react";
import "../assets/css/Footer.css";
import { FaInstagram } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import footerLogo from "../assets/images/footerlogo.png";

const Footer = () => {
  return (
    <>
      {" "}
      <div className="col-12 bg-white border-radius-style footer">
        <div className="col-12 p-3 ">
          <div className="d-flex">
            <div className="card-type-three">
              <a target="_blank" rel="noreferrer" aria-label="instagram" href="https://www.instagram.com/deka.energy?igsh=NzBmMjdhZWRiYQ==">   <FaInstagram /></a>
           
            </div>
            <div className="card-type-three">
              <a target="_blank" rel="noreferrer" aria-label="facebook" href="https://www.facebook.com/profile.php?id=61553598159534"><RiFacebookFill /></a>
              
            </div>
            <div className="card-type-three">
              <a target="_blank" rel="noreferrer" aria-label="twitter" href="https://x.com/dekaenergy?s=21">   <FaXTwitter /></a>
           
            </div>
            <div className="card-type-three">
              <a target="_blank" rel="noreferrer" aria-label="linkedin" href="https://tr.linkedin.com/company/dekaenerji">  <FaLinkedinIn /></a>
            
            </div>
          </div>
        </div>
        <div className="col-12 d-lg-flex">
          <div className="col-lg-6 col-12 p-3 mt-1 d-flex flex-column justify-content-between">
            <div className="d-flex">
              {" "}
              <div className="col-6 text-start">
                {" "}
                <h6>
                  <b>
                    energizes <br />
                    you.
                  </b>
                </h6>
              </div>
              {/* <div className="col-6 d-flex justify-content-end">
              {" "}
              <a href="/enterprise">
                <button className="button-type">lorem_ipsum</button>
              </a>
            </div>{" "} */}
            </div>
            <div className="col-12 d-flex flex-wrap mt-4 footer-main justify-content-center align-items-center">
              <div className="col-lg-3 col-12 text-start">
                <a href="/kurumsal" aria-label="kurumsal-footer">
                  <h6>
                    <b>kurumsal</b>
                  </h6>
                </a>
                <a href="/hizmetlerimiz" aria-label="hizmetlerimiz-footer">
                  {" "}
                  <h6>
                    <b>hizmetlerimiz</b>
                  </h6>
                </a>
                <a href="/deneyimlerimiz" aria-label="deneyimlerimiz-footer">
                  {" "}
                  <h6>
                    <b>deneyim</b>
                  </h6>
                </a>
              </div>
              <div className="col-lg-3 col-12 text-start">
                <a href="/makaleler" aria-label="makaleler-footer">
                  <h6>
                    <b>makaleler</b>
                  </h6>
                </a>

                <a href="/sikca-sorulan-sorular" aria-label="sss-footer">
                  {" "}
                  <h6>
                    <b>sss</b>
                  </h6>
                </a>

                <a href="/iletisim" aria-label="iletisim-footer">
                  {" "}
                  <h6>
                    <b>iletişim</b>
                  </h6>
                </a>
              </div>
              <div className="col-lg-3 col-12 text-start">
                <h6 className="p-0 m-0">
                  <b>adres</b>
                </h6>
                <p className="me-2 ">
                  Flexi Ofis Yenişehir Mah. Demokrasi Blv. No:54 İç Kapı No: A
                  İzmit/Kocaeli
                </p>
              </div>

              <div className="col-lg-3 col-12 text-start">
                <h6 className="p-0 m-0">
                  <b>telefon</b>
                </h6>
                <p>
                  <a aria-label="phone" href="tel:+908503027041">+90 850 302 70 41</a>
                </p>
                <h6 className="p-0 m-0">e-mail</h6>
                <p>
                  <a aria-label="mail" href="mailto:info@dekasolar.com">info@dekasolar.com</a>
                </p>
              </div>
            </div>
            <div className="col-12 madeby_text ">
              <div className="d-flex footer-bottom-line">
                <p>made by </p>{" "}
                <a aria-label="osi-crew" target="_blank" href="www.osicrew.com">
                  &nbsp; osiCrew
                </a>
                {/* <img className="osicrew-img" src={osilogo}></img> */}
              </div>
              <p className="text-start">
                copyright © 2023 | osi | all rights reserved.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-12 d-flex align-items-end  ">
            <img src={footerLogo} alt="deka solar enerji"></img>
          </div>
        </div>
      </div>
      <div className="col-12 h_10"></div>
    </>
  );
};

export default Footer;
