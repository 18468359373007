import React, { useEffect, useState } from "react";
import "../assets/css/enterprise.css";
import { Fade } from "react-reveal";
import noImgTwo from "../assets/images/deka_logotype.svg";
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";
import blogsData from "../data/blog.json";
import {Helmet} from "react-helmet";

const ArticlesMain = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);
  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }
  return (
    <>
      <Helmet>
        <title>Makaleler | Deka Solar</title>
        <meta name="description" content="DEKA Enerji, sürdürülebilir enerji alanında ilham verici bilgiler sunarak, geleceğe yönelik inovatif projeler ve çevreyi birleştiren teknolojik çözümleriyle öne çıkıyor."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/makaleler"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container blog-main justify-content-center align-items-center">
            <div className="bg-light container inner-container bg-articles d-flex justify-content-center align-items-center p-0">
              <div className="col-12 text-start h-100 ">
                <div className="col-lg-9 col-12  text-white  d-flex flex-column justify-content-end p-3 mt-5">
                  <h1>
                    <b>Makaleler</b>
                  </h1>
                  <h5>
                    <b>sürdürülebilir enerjiye dair İlham veren bilgiler</b>
                  </h5>
                  <p>
                    DEKA Enerji, sürdürülebilir enerji alanında ilham verici
                    bilgiler sunarak, geleceğe yönelik inovatif projeler ve
                    çevreyi birleştiren teknolojik çözümleriyle öne çıkıyor.
                    Enerji sektöründeki yenilikçi yaklaşımlarıyla dikkat çeken
                    firmamız, güncel projelerimizle sürdürülebilir enerjiye
                    katkıda bulunuyor ve sektördeki gelişmeleri yakından takip
                    ediyor.
                  </p>
                </div>
                <div className="col-12 ">
                  <div className="card-type-four bg-articles-card main-radius  d-lg-flex text-white m-3">
                    <div className="col-lg-4 col-12 p-4 d-flex align-items-center">
                      <img
                        className="main-radius h-100"
                        src={noImgTwo}
                        alt="deka solar enerji"
                      ></img>
                    </div>
                    <div className="col-lg-7 d-flex justify-content-center flex-column  col-12 p-3">
                      <p>
                        Siz de sürdürülebilir enerjiye dair ilginç bilgiler
                        edinmek ve DEKA Enerji'nin projelerini daha yakından
                        tanımak için makalelerimizi inceleyebilirsiniz. Güncel
                        gelişmelerden haberdar olmak ve geleceğin enerji
                        trendlerine yön veren çözümleri keşfetmek için bizi
                        takip etmeye devam edin.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container col-12 ">
            <div className="d-lg-flex d-md-flex justify-content-center p-0 flex-wrap mb-3">
              {blogsData.map((blog, index) => (
                <div
                  key={blog.blog_no}
                  className="col-lg-4 col-md-6 col-12 p-lg-2 p-1"
                >
                  <div className="card-type-two text-start d-flex mb-3">
                    <div className="col-5 d-flex align-items-center justify-content-center">
                      <img
                        src={blog.img || noImgTwo}
                        className="p-lg-2 p-4"
                        alt={blog.title}
                      />
                    </div>
                    <div className="col-7 d-flex justify-content-center flex-column p-lg-2">
                      <h5>
                        <b>
                          {blog.title.length > 90
                            ? blog.title.slice(0, 80) + "..."
                            : blog.title}
                        </b>
                      </h5>
                      <a
                        href={`/makaleler/${removeTurkishChars(blog.title)
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`}
                      >
                        <button className="button-type p-2">daha fazla</button>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Footer />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ArticlesMain;
